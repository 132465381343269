import { useEffect, useState, type FC } from 'react';
import styles from './index.module.scss';
import copyIcon from '@assets/images/pump/copy.svg';
import commentsIcon from '@assets/images/pump/comments.svg';
import historyIcon from '@assets/images/pump/history.svg';
import redWarnIcon from '@assets/images/pump/red-warn.svg';
import topIcon from '@assets/images/pump/top.svg';
import Top from '@components/top';
import Back from '@ui/back';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PumpChart from '@components/pump-chart';
import Holder from '@components/holder';
import Process from '@components/process';
import { formatAddress } from '@mysten/sui/utils';
import BuyAndSell from '@components/pump-buy-and-sell';
import Comments from '@components/comments';
import { usePumpPool } from '@hooks/use-pump-pool';
import { useCopyToClipboard } from 'react-use';
import { toast } from 'react-toastify';
import { bigNumberToReadable } from '@utils/big-number-to-readable';
import ContactType from '@components/contact-type';
import PumpHistory from '@components/pump-history';
import Loading from '@ui/loading';
import PumpSwap from '@components/pump-swap';
import PumpCompleted from '@components/pump-completed';
import { globalSettingModel } from '@models/global-setting.model';
import Footer from '@components/footer';
import FormatZeroDecimal from '@components/format-zero-decimal';
import { usePool } from '@hooks/use-pool';
import { timeDifference } from '@utils/time-difference';
import { formatContract } from '@utils/format-contract';
import { useLatestPrice } from '@hooks/use-last-price';
import { useResolveSuiNSName, useSuiClient } from '@mysten/dapp-kit';

const tabs = [
  {
    value: 'comments',
    label: 'Comments',
    icon: commentsIcon,
  },
  {
    value: 'history',
    label: 'Trading History',
    icon: historyIcon,
  },
];

const PumpDetail: FC = () => {
  const navigate = useNavigate();
  const coinType = useParams<{ id: string }>().id!;

  const [, copy] = useCopyToClipboard();
  const [tab, setTab] = useState('comments');
  const [isBlack, setIsBlack] = useState(false);

  const pool = usePumpPool(coinType);
  const poolFields = usePool(coinType);

  const latestPrice = useLatestPrice(coinType);
  const suiNames = useResolveSuiNSName(pool?.created_by);
  const client = useSuiClient();

  const getIsBlack = async () => {
    const coin = await client.getCoinMetadata({
      coinType: coinType,
    });

    if (coin && !!coin.id) {
      const result = await client.getObject({
        id: coin.id,
        options: {
          showPreviousTransaction: true,
        },
      });
      if (result.data?.previousTransaction) {
        const res = await client.getTransactionBlock({
          digest: result.data?.previousTransaction,
          options: {
            showObjectChanges: true,
          },
        });
        const finalResult = (res.objectChanges as { objectType: string }[])?.some(
          (item) => item.objectType === `0x2::coin::RegulatedCoinMetadata<${coinType}>`,
        );
        setIsBlack(finalResult);
      }
    }
  };

  useEffect(() => {
    getIsBlack();
  }, []);

  return (
    <>
      <Top />

      <div className={styles.wrapper}>
        {isBlack && (
          <div className={styles.black_tip}>
            <img src={redWarnIcon} />
            <span>This token is identified as a honeypot scam. Do not trade.</span>
            <img src={redWarnIcon} />
          </div>
        )}
        <div className={styles.wrapper_top}>
          <Back message="Back" onClick={() => navigate(-1)} className={styles.back} />
        </div>
        {!pool || !poolFields ? (
          <div className={styles.loading}>
            <Loading size={64} />
          </div>
        ) : (
          <>
            <div className={styles.first}>
              <div className={styles.icon}>
                <img src={pool.uri} />
              </div>

              <div className={styles.content}>
                <div className={styles.title}>
                  {pool.name} (${pool.symbol})
                  <ContactType
                    website={pool.website}
                    twitter={pool.twitter}
                    telegram={pool.telegram}
                    className={styles.contact}
                  />
                </div>
                <div className={styles.contract}>
                  CA: <span>{`${formatContract(coinType)}`}</span>
                  <img
                    src={copyIcon}
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                      event.stopPropagation();
                      copy(pool.token_address);
                      toast('Address is copied');
                    }}
                  />
                </div>
                <div className={styles.created}>
                  Created by:{' '}
                  <Link
                    to={globalSettingModel.explorerUrlForAccount(pool.created_by)}
                    target="_blank"
                  >
                    {suiNames.data ? suiNames.data : formatAddress(pool.created_by)}
                  </Link>
                  <span>{timeDifference(pool.created_at, 1)} ago</span>
                </div>

                <div className={styles.description}>{pool.description}</div>
                <div className={styles.data}>
                  <div className={styles.line}>
                    <p>Price</p>
                    <p>
                      {pool.token_price_sui ? (
                        <>
                          {pool.is_completed && '$'}
                          <FormatZeroDecimal
                            value={bigNumberToReadable(
                              pool.is_completed
                                ? latestPrice
                                  ? latestPrice.price_usd
                                  : pool.token_price_usd
                                : latestPrice
                                ? latestPrice.price_sui
                                : pool.token_price_sui,
                              0,
                            )}
                          />{' '}
                          {!pool.is_completed && '$SUI'}
                        </>
                      ) : (
                        '-'
                      )}
                    </p>
                  </div>
                  <div className={styles.line}>
                    <p>Market Cap</p>
                    <p>
                      {pool.market_cap_usd
                        ? `$${bigNumberToReadable(pool.market_cap_usd.toString(), 0, true, 2)}`
                        : '-'}
                    </p>
                  </div>
                  <div className={styles.line}>
                    <p>24H Volume</p>
                    <p>
                      {!pool.is_completed && pool.volume_24h_sui
                        ? `${bigNumberToReadable(pool.volume_24h_sui?.toString(), 0, true)} $SUI`
                        : pool.is_completed && pool.volume_24h_usd
                        ? `$${bigNumberToReadable(pool.volume_24h_usd?.toString(), 0, true)}`
                        : '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.main}>
              <div className={styles.left}>
                <PumpChart pool={pool} />
                <div className={styles.holder_left}>
                  <Holder pool={pool} />
                </div>
                <div className={styles.block}>
                  <div className={styles.tabs}>
                    {tabs.map((item) => (
                      <div
                        className={item.value === tab ? styles.active : ''}
                        key={item.value}
                        onClick={() => setTab(item.value)}
                      >
                        <img src={item.icon} />
                        {item.label}
                      </div>
                    ))}
                  </div>

                  {tab === 'comments' && <Comments pool={pool} />}
                  {tab === 'history' && <PumpHistory pool={pool} />}
                </div>
              </div>
              <div className={styles.right}>
                {pool.is_completed ? (
                  pool.clmm_pool_id ? (
                    <PumpSwap pool={pool} />
                  ) : (
                    <PumpCompleted />
                  )
                ) : (
                  <BuyAndSell pool={pool} poolFields={poolFields} isBlack={isBlack} />
                )}
                {!pool.clmm_pool_id && <Process pool={pool} poolFields={poolFields} />}
                <div className={styles.holder_right}>
                  <Holder pool={pool} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
      <div
        className={styles.fix_top}
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      >
        <img src={topIcon} />
      </div>
    </>
  );
};

export default PumpDetail;
